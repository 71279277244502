import React from "react";
import { Link } from "gatsby";

const Paginationtalk = () => {
  return (
    <nav aria-label="Page navigation example">
      <ul className="numberpage">
        <li className="numberpagepara">
          <Link className="pagenumbertext" to="/">
            prev
          </Link>
        </li>
        <li className="numberpagepara2 active">
          <Link className="pagenumbertext" to="/">
            1
          </Link>
        </li>
        <li className="numberpagepara2">
          <Link className="pagenumbertext" to="/">
            2
          </Link>
        </li>
        <li className="numberpagepara">
          <Link className="pagenumbertext" to="/">
            next
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Paginationtalk;
