import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ScrollUpButton from "react-scroll-up-button";
import adsspaceBg from "../images/adsspaceBg.jpg";
import Paginationtalk from "../components/hedgetalks/Paginationtalk";
import goldx from "../images/icons/goldx.svg";
import goldcall from "../images/icons/goldcall.svg";
import goldlock from "../images/icons/goldlock.svg";
import goldVideo from "../images/icons/goldVideo.svg";
import goldwrite from "../images/icons/goldwrite.svg";

const Kycaudit = () => {
  return (
    <Layout>
      <ScrollUpButton ContainerClassName="custom-scroll-button" />
      <Seo title="KYC Audit" />
      <Container className="py-5">
        <Row className="mt-5 justify-content-start">
          <Col lg={9} className="mb-5">
            <h3 className="left-title">KYC/Audit Security Center</h3>
            <div className="goldline"></div>

            <div>
              <p>
              KYC Verification and Audit Service is a mandatory regulation for all businesses. This ensures the legitimacy of your customer&#39;s activities and identifies potential project-killing bugs. Improving security is the most valuable investment making it a worthwhile investment in your project&#39;s future.
              </p>
            </div>
          </Col>
          <Col lg={3}>
            <div className="d-flex flex-row-reverse talkbtn">
              <button className="hedgetalkPribtn">Sign Up</button>
            </div>
          </Col>
        </Row>
        <div></div>
        <Row className="my-5">
          <Col lg={12}>
            <div className="w-100 d-flex flex-md-row flex-column position-relative">
              <div className="px-4 py-3 px-sm-5 py-sm-4 leftslide"></div>
              <div className="rightslide position-relative">
                <img
                  className="w-100 h-100 position-absolute top-0 start-0 img-fluid leftfeaimage imgadscover"
                  src={adsspaceBg}
                  alt="ads space Bg"
                />
              </div>
              <div className="w-100 h-100 kycadscover position-absolute"></div>
              <div className="w-100 h-100 kycadscover3 position-absolute d-flex flex-md-row flex-column">
                <div className="w-100 h-100 position-relative">
                  <div className="position-absolute d-flex flex-column align-items-center justify-content-center adsspaceparent">
                    <h2 className="text-center rightslidehead">Ads Space for Leasing!</h2>
                    <span className="rightslidepara pb-4">Have you found the right place for your ads yet?</span>
                    <button className="rightslidebtn">Find out more!</button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="wholefealist">
          <Col lg={4} className="d-flex align-items-center justify-content-center feasellitem topleftborfea">
            <div className="kycfeaheading text-center">HedgePay KYC Verification</div>
          </Col>
          <Col lg={4} className="d-flex align-items-center justify-content-center feasellitem topcenterborfea">
            <div className="d-flex flex-column align-items-center justify-content-center ">
              <div className="mb-2">
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <div className="d-flex flex-column lefttitle">
                    <span className="feaselltophead">Only</span>
                    <span className="feaselltopsub">owners</span>
                  </div>
                  <img src={goldx} alt="" />
                </div>
              </div>
              <p className="feasellcontent">
                KYC verification are strictly for project owners that hold the contract and wallet. HedgePay will not
                accept any KYC from team for security purposes
              </p>
            </div>
          </Col>
          <Col lg={4} className="d-flex align-items-center justify-content-center feasellitem toprightborfea">
            <div className="d-flex flex-column align-items-center justify-content-center ">
              <div className="mb-2">
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <div className="d-flex flex-column lefttitle">
                    <span className="feaselltophead">Live</span>
                    <span className="feaselltopsub">Videos</span>
                  </div>
                  <img src={goldVideo} alt="" />
                </div>
              </div>
              <p className="feasellcontent">
                During the KYC, owner are required to show us the entire process of logging into the contract and wallet
                through a video call and video recording
              </p>
            </div>
          </Col>
          <Col lg={4} className="d-flex align-items-center justify-content-center feasellitem botmleftborfea">
            <div className="d-flex flex-column align-items-center justify-content-center ">
              <div className="mb-2">
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <div className="d-flex flex-column lefttitle">
                    <span className="feaselltophead">Third</span>
                    <span className="feaselltopsub">Party</span>
                  </div>
                  <img src={goldlock} alt="" />
                </div>
              </div>
              <p className="feasellcontent">
                Personal information such as IDs, video recordings, and pictures will be stored by a trusted third party
                provider
              </p>
            </div>
          </Col>
          <Col lg={4} className="d-flex align-items-center justify-content-center feasellitem">
            <div className="d-flex flex-column align-items-center justify-content-center ">
              <div className="mb-2">
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <div className="d-flex flex-column lefttitle">
                    <span className="feaselltophead">Miss</span>
                    <span className="feaselltopsub">Call</span>
                  </div>
                  <img src={goldcall} alt="" />
                </div>
              </div>
              <p className="feasellcontent">
                A suprise video call will be performed to verify information provided to HedgePay. Failure to answer
                calls can lead to failed verification
              </p>
            </div>
          </Col>
          <Col lg={4} className="d-flex align-items-center justify-content-center feasellitem botmrightborfea">
            <div className="d-flex flex-column align-items-center justify-content-center ">
              <div className="mb-2">
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <div className="d-flex flex-column lefttitle">
                    <span className="feaselltophead">Write</span>
                    <span className="feaselltopsub">To Us</span>
                  </div>
                  <img src={goldwrite} alt="" />
                </div>
              </div>
              <p className="feasellcontent">
                To report a scam, fill out this evidence form. This will launch an investigation by HedgePay security,
                that may result in ID handed to the relevant authority
              </p>
            </div>
          </Col>
        </Row>

        <Row className="my-5">
          <Col className="d-flex align-items-center justify-content-center">
            <div className="d-flex flex-row-reverse talkbtn">
              <button className="hedgetalkPribtn">Sign Up</button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <Paginationtalk></Paginationtalk>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Kycaudit;
